import * as nav from "./modules/navigation.js";
import * as swiper from "./modules/carousels.js";
import animateOnScroll from "./modules/observer.js";
import initVideoPlayer from "./modules/videoControls.js";

// Stop transitions before page has loaded
window.addEventListener(
  "load",
  (event) => {
    document.body.classList.remove("preload");
  },
  false,
);

$(".accordion-toggle").click(function () {
  $(this).next().slideToggle();
});

$(".scrollToBody").click(function () {
  const hero = $(this).parent();

  $("html, body").animate(
    {
      scrollTop: hero.prop("scrollHeight") - 64,
    },
    500,
  );
});
