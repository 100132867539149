import Swiper from "swiper/swiper-bundle.mjs";

// Horizontal scroll
const imageGallery = new Swiper(".image-gallery", {
  centeredSlides: true,
  loop: true,
  slidesPerView: 1,
  spaceBetween: 24,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  breakpoints: {
    768: {
      slidesPerView: 2,
    },
  },
});

const slideshow = new Swiper(".slideshow", {
  loop: true,
  slidesPerView: 1,
  spaceBetween: 24,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});

const linkCarousel = new Swiper(".link-carousel", {
  slidesPerView: 1,
  spaceBetween: 24,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  breakpoints: {
    640: {
      slidesPerView: 2,
    },
    768: {
      slidesPerView: 3,
    },
  },
});

export { imageGallery, slideshow, linkCarousel };
