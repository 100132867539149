function videoPlayer(target) {
  const videoContainer = target;
  const video = videoContainer.children[0];
  const playpause = videoContainer.children[1];
  var progress = videoContainer.children[2];
  var progressBar = progress.children[0];

  // Add events for all buttons
  video.addEventListener("click", function (e) {
    if (video.paused || video.ended) video.play();
    else video.pause();
  });

  playpause.addEventListener("click", function (e) {
    if (video.paused || video.ended) video.play();
    else video.pause();
  });

  // Add event listeners for video specific events
  video.addEventListener(
    "play",
    function () {
      playpause.classList.add("opacity-0");
      progress.classList.remove("opacity-0");
    },
    false,
  );

  video.addEventListener(
    "pause",
    function () {
      playpause.classList.remove("opacity-0");
    },
    false,
  );

  // As the video is playing, update the progress bar
  video.addEventListener("timeupdate", function () {
    // For mobile browsers, ensure that the progress element's max attribute is set
    if (!progress.getAttribute("max"))
      progress.setAttribute("max", video.duration);
    progress.value = video.currentTime;
    progressBar.style.width =
      Math.floor((video.currentTime / video.duration) * 100) + "%";
  });

  // React to the user clicking within the progress bar
  progress.addEventListener("click", function (e) {
    //var pos = (e.pageX  - this.offsetLeft) / this.offsetWidth; // Also need to take the parent into account here as .controls now has position:relative
    var pos =
      (e.pageX - (this.offsetLeft + this.offsetParent.offsetLeft)) /
      this.offsetWidth;
    video.currentTime = pos * video.duration;
  });
}

const initVideoPlayer = window.addEventListener(
  "load",
  (event) => {
    document.querySelectorAll(".videoPlayer").forEach(function (i) {
      if (i) {
        videoPlayer(i);
      }
    });
  },
  false,
);

export { initVideoPlayer };
