const menu = document.querySelector("#main-navigation");
const hamburger = document.querySelector("#hamburger");
const menuIcon = document.querySelector(".menuIcon");
const closeIcon = document.querySelector(".closeIcon");

// Toggle nav
hamburger.addEventListener("click", function () {
  menu.classList.contains("tw-hidden") ? openNav() : closeNav();
});

// Hide nav on window resise
window.addEventListener("resize", (event) => {
  if (window.innerWidth > 900) {
    closeNav();
  }
});

// Functions
const openNav = () => {
  menu.classList.replace("tw-hidden", "flex");
  menuIcon.classList.add("tw-hidden");
  closeIcon.classList.remove("tw-hidden");
};

const closeNav = () => {
  menu.classList.replace("flex", "tw-hidden");
  menuIcon.classList.remove("tw-hidden");
  closeIcon.classList.add("tw-hidden");
};

export { openNav, closeNav };
